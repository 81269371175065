//
//
//
//
//

import OrderList from "@/page/admin/myOrder/flightOrder/component/list/1.0.1/index.vue";
export default {
  data() {
    return {
      statusList: [
          {
              value: "",
              label: "全部"
          },
          {
              value: 13,
              label: "超标审批中"
          },
          {
              value: 11,
              label: "待支付"
          },
          {
              value: 21,
              label: "待出票"
          },
          {
              value: 22,
              label: "已出票"
          },
          {
              value: 90,
              label: "已取消"
          },
          {
              value: 23,
              label: "出票失败"
          }
      ]
    };
  },
  components: {
    OrderList
  },
  methods: {},
  created() {},
  activated() {},
  watch: {},
  computed: {}
};
